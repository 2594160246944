import React, { useState } from "react"
import Layout from "../components/layout"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import { Process, Toolset, Title } from "../components/parts"

const Page = () => {
  const [selectedType, setType] = useState(photoTypes[0])
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const displayPhotos = photos.filter(p =>
    selectedType === photoTypes[0] ? true : p.tags.indexOf(selectedType) >= 0
  )

  return (
    <Layout title="Sketchbook">
      <Title heading="Sketchbook" />
      <div className="text-center">
        {photoTypes.map((p, i) => {
          let className = `filters-tag`
          let tick = null

          if (selectedType === p) {
            className += " is-active"
            tick = <img src="/images/tick.svg" alt="tick" />
          }

          return (
            <span
              key={p}
              className={className}
              onClick={() => setType(p)}
              onKeyDown={() => setType(p)}
              role="button"
              tabIndex={i + 1}
            >
              {tick}
              {p}
            </span>
          )
        })}
        <p className="filters-count">
          {displayPhotos.length} / {photos.length} sketches.{" "}
          {selectedType !== photoTypes[0] ? (
            <span
              role="button"
              onClick={() => setType(photoTypes[0])}
              onKeyDown={() => setType(photoTypes[0])}
              tabIndex={photoTypes.length + 1}
            >
              Show all
            </span>
          ) : null}
        </p>
      </div>
      <div className="section">
        <Gallery photos={displayPhotos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox} backdropClosesModal={true}>
              <Carousel
                currentIndex={currentImage}
                views={displayPhotos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
                trackProps={{
                  swipe: "touch",
                  infinite: false,
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      <Process text="See how its done" title="Sketching process">
        <iframe
          title="embed"
          width="100%"
          height="420"
          src="https://www.youtube.com/embed/HwFiRE29Ono"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          title="embed"
          width="100%"
          height="420"
          src="https://www.youtube.com/embed/F4xTOkfjXic"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          title="embed"
          width="100%"
          height="420"
          src="https://www.youtube.com/embed/OECa36fq2lk"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Process>
      <Toolset
        tools={["procreate", "sketchbook", "pencil", "illustrator", "paint"]}
      />
    </Layout>
  )
}

export default Page

const photoTypes = [
  "All",
  "iPad Sketch",
  "Adobe illustrator",
  "Pencil on paper",
  "Paint on canvas",
]

const photos = [
  {
    src: "/images/sketchbook/1.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/2.jpg",
    width: 3,
    height: 4,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/3.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/4.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/5.jpg",
    width: 4,
    height: 3,
    tags: ["Pencil on paper"],
  },
  {
    src: "/images/sketchbook/6.jpg",
    width: 1,
    height: 1,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/7.jpg",
    width: 3,
    height: 4,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/8.jpg",
    width: 1,
    height: 1,
    tags: ["Paint on canvas"],
  },
  {
    src: "/images/sketchbook/9.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/10.jpg",
    width: 3,
    height: 2,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/11.jpg",
    width: 3,
    height: 2,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/12.jpg",
    width: 3,
    height: 2,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/13.jpg",
    width: 3,
    height: 2,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/14.jpg",
    width: 3,
    height: 2,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/15.jpg",
    width: 3,
    height: 2,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/16.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/17.jpg",
    width: 3,
    height: 4,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/18.jpg",
    width: 55,
    height: 64,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/19.jpg",
    width: 3,
    height: 4,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/20.jpg",
    width: 3,
    height: 4,
    tags: ["Pencil on paper"],
  },
  {
    src: "/images/sketchbook/21.jpg",
    width: 3,
    height: 2,
    tags: ["Adobe illustrator"],
  },
  {
    src: "/images/sketchbook/22.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/23.jpg",
    width: 1,
    height: 1,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/24.jpg",
    width: 2,
    height: 1.1,
    tags: ["Adobe illustrator"],
  },
  {
    src: "/images/sketchbook/25.jpg",
    width: 3,
    height: 4,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/26.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/27.jpg",
    width: 4,
    height: 3,
    tags: ["Pencil on paper"],
  },
  {
    src: "/images/sketchbook/28.jpg",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/29.png",
    width: 4,
    height: 3,
    tags: ["iPad Sketch"],
  },
  {
    src: "/images/sketchbook/30.jpg",
    width: 3,
    height: 4,
    tags: ["Pencil on paper"],
  },
  {
    src: "/images/sketchbook/31.jpg",
    width: 1,
    height: 1,
    tags: ["Paint on canvas"],
  },
]
